<template>
   <div>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
         <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
         <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-row class="bradcrumb">
         <router-link :to="{name: 'home' }" class="root">
            Dashboard
         </router-link>
         /
         <span class="child"> User Profile </span>
      </v-row>
      <br />
      <v-card class="card">
         <v-card-title class="heading justify-center">User Profile</v-card-title>
         <v-card-text>
            <v-container>
               <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" >
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12" >
                                <div class="file-upload" >
                                    <span v-if="photoFilename">
                                        <img :src="photo" />
                                    </span>
                                    <span v-else>
                                        <img src="https://easycheck.s3.ap-south-1.amazonaws.com/logo/user-profile.png" />
                                    </span>
                                    <!-- <input  type="file" name="somename" :v-model="photoFile"/> -->
                                </div>
                                <v-file-input
                                        show-size
                                        label=" Select Photo File "
                                        v-model="photoFile"
                                        accept="image/jpeg, image/jpg"
                                    ></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" >
                                 <v-text-field
                                    label="Full Name*"
                                    required
                                    hint="First Name - Middel Name - Last Name"
                                    v-model="name"
                                ></v-text-field>
                            </v-col>
                       </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" >
                      <br>
                       <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" >
                            <v-text-field
                                label="Mobile Number*"
                                required
                                v-model="mobileno"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" >
                            <v-text-field
                                label="Email*"
                                required
                                v-model="email"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" >
                            <v-text-field
                                label="Address*"
                                required
                                v-model="address"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" >
                            <v-text-field
                                label="City*"
                                required
                                v-model="city"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" >
                            <v-text-field
                                label="State*"
                                required
                                v-model="state"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" >
                            <v-text-field
                                label="Country*"
                                required
                                v-model="country"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" >
                            <v-text-field
                                label="Pin Code*"
                                required
                                v-model="pin"
                            ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-col>
               </v-row>
            </v-container>
             <center>
                <v-btn class="button" @click="save" >Save</v-btn>
             </center>
         </v-card-text>
            
      </v-card>
   </div>
</template>
<script>
   import axios from "axios";
   import "@/assets/css/color.css"; // css needs to be imported for each dashboard
   export default {
       data: () => ({
           snackbar_msg: "",
           color: "",
           menu1: false,
           menu2: false,
           snackbar: false,
           name:null,
           mobileno:null,
           email:null,
           address:null,
           city:null,
           state:null,
           country:null,
           pin:null,
           photoFilename:null,
           photo:null,
           photoFile:null,
       }),
       mounted() {
           this.onLoad();
       },
       methods: {
           onLoad() {
               axios
                   .post("/Profile/getProfileData")
                   .then((res) => {
                       if (res.data.msg == "200") {
                           this.photo=res.data.photoPath
                           this.photoFilename=res.data.profilephotofilename
                           this.name=res.data.name
                           this.mobileno=res.data.mobile_number
                           this.email=res.data.email
                           this.address=res.data.address
                           this.city=res.data.city
                           this.state=res.data.state
                           this.country=res.data.country
                           this.pin=res.data.pin
                       }
                   })
                   .catch((error) => {
                       this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                       window.console.log(error);
                   })
                   .finally(() => {
                       // var overlay = false;
                   });
           },
           showSnackbar(clr, msg) {
               this.snackbar = true;
               this.color = clr;
               this.snackbar_msg = msg;
           },
          
           save() {
              let formData = new FormData();
                formData.append("photoFile", this.photoFile);
                formData.append("name", this.name);
                formData.append("mobileno", this.mobileno);
                formData.append("email", this.email);
                formData.append("address", this.address);
                formData.append("city", this.city);
                formData.append("state", this.state);
                formData.append("country", this.country);
                formData.append("pin", this.pin);
                       axios
                           .post("/Profile/saveProfileData", formData)
                           .then((res) => {
                               if (res.data.msg == "200") {
                                   this.showSnackbar("#4caf50", "Profile Updated Successfully..."); // show snackbar on success
                                   this.onLoad();
                                   this.photoFile=null
                               } else  {
                                   this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar on error
                               }
                           })
                           .catch((error) => {
                               window.console.log(error);
                           });
                       this.close();
                   }
               
           }, //save()
   
          
   };
</script>
<style scoped>
.file-upload{
	height:200px;
	width:200px;
	margin:55px auto;
	border:1px solid #f0c0d0;
	border-radius:10px;
	overflow:hidden;
	position:relative;
}
.file-upload input{
	position:absolute;
	height:400px;
	width:400px;
	left:-200px;
	top:-200px;
	background:transparent;
	opacity:0;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);  
}
.file-upload img{
	height:168px;
	width:168px;
	margin:15px;
}
   .v-text-field {
   padding-top: 0px;
   margin-top: 0px;
   }
   .v-icon-size {
   font-size: 20px;
   }
   .edit-avatar {
   transition: 0.3s;
   border: 1px solid #b0bec5;
   }
   .edit-avatar:hover {
   background-color: #b0bec5;
   }
   .edit-v-icon {
   padding-left: 6px;
   font-size: 15px;
   }
   .edit-v-icon:hover {
   color: white;
   }
   .fields {
   padding: 0px !important;
   }
   .isdonated {
   margin-top: 15px;
   padding-left: 15px !important;
   }
   .check {
   color: green;
   font-size: 20px;
   padding-right: 10px;
   }
   .cross {
   color: red;
   font-size: 20px;
   padding-right: 5px;
   }
</style>

